import React, { useContext, useEffect, useMemo, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/layout';
import Meta from '../../components/meta';
import { Text, Button, Header } from '../../components';
import useLocalStorage from '../../hooks/useLocalstorage';
import { get } from '../../services/api';
import { isBrowser } from '../../helpers/isBrowser';
import GlobalContext from '../../context/global-context';
import interpolateValues from '../../utils/interpolation';

const INTERVAL_DURATION = 30000;

const Stat = ({ value }) => {
	const [interpolatedValue, setInterpolatedValue] = useState(0);
	const setValue = (value) => {
		setInterpolatedValue(value);
	};
	useEffect(() => {
		typeof value === 'string' ? value = parseFloat(value.replace(',','.')) : null;
		let hasDecimals = false;
		if(value - Math.floor(value) !== 0) {
			hasDecimals = true;
		}
		interpolateValues(setValue, interpolatedValue, value, hasDecimals);
	}, [value]);
	return (
		<p className="inline-block text-white-100 font-nove text-18">{interpolatedValue}</p>
	);
};

const ChoiceAnswer = ({ data }) => (
	<>
		<Text type="statText">{'\u00A0'}Klassens mest valgte svar er{'\u00A0'}</Text>
		<Stat value={data.choice_answers.choice_answer_dk}></Stat>
		<Text type="statText">{'\u00A0'}for Danmark og{'\u00A0'}</Text>
		<Stat value={data.choice_answers.choice_answer_ke}></Stat>
		<Text type="statText">{'\u00A0'}for Kenya.</Text>
	</>
);

const SliderAnswer = ({ data }) => (
	<>
		<Text type="statText">{'\u00A0'}Klassen svarede i gennemsnit{'\u00A0'}</Text>
		<Stat value={data.slider_answers.slider_answer_dk}></Stat>
		<Text type="statText">{'\u00A0'}for Danmark og{'\u00A0'}</Text>
		<Stat value={data.slider_answers.slider_answer_ke}></Stat>
		<Text type="statText">{'\u00A0'}for Kenya.</Text>
	</>
);

const Page = ({ data }) => {
	const [themeQuestions, setThemeQuestions] = useState([]);
	const [sktCode] = useLocalStorage('FKN_SKT');
	const [theme] = useLocalStorage('FKN_Theme', '');
	const context = useContext(GlobalContext);
	const [stats, setStats] = useState([]);
	const [countdown, setCountdown] = useState(INTERVAL_DURATION / 1000);

	const BASEURL = isBrowser() ? window.location.origin : '';
	const subject = 'Klasselink til Kenyakortet';
	const body = `Her er vores klasses link til Kenyakortet: ${BASEURL}/explore?=${sktCode}`;

	// gets all data from question and answer pages 
	const { allStoryblokEntry: { nodes }} = data;
	const [questionPages, answerPages] = useMemo(() => {
		const questionPages = nodes.filter(node => node.full_slug.includes('sporgsmal'));
		for(const questionPage of questionPages) {
			try {
				questionPage.content = JSON.parse(questionPage.content);
			} catch (e) {
				console.warn('Content already parsed');
			}
		}
		const answerPages = nodes.filter(node => node.full_slug.includes('svar'));
		for(const answerPage of answerPages) {
			try {
				answerPage.content = JSON.parse(answerPage.content);
			} catch (e) {
				console.warn('Content already parsed');
			}
		}
		return [questionPages, answerPages];
	}, [data]);

	useEffect(() =>{
		const questions = questionPages
			.filter(questionPage => questionPage.tag_list.includes(theme))
			.map(qp => {
				const answerpage = answerPages.find(answerPage => answerPage.uuid === qp.content.body[0]?.answer_page_link?.id);
				const answer = answerpage.content;
				return ({
					...qp,
					answers: {
						denmark: answer?.body[0]?.Denmark_answer || 0,
						kenya: answer?.body[0]?.Kenya_answer || 0,
					},
				});
			});
		setThemeQuestions(questions);
	}, []);

	const getStatsData = async () => {
		const statsData = await get(`klasse/${sktCode}/stats`);
		setStats(statsData.stats);
		setCountdown(30);
	};


	useEffect(() => {
		getStatsData();
		const dataInterval = setInterval(() => getStatsData(), INTERVAL_DURATION);
		const countdownInterval = setInterval(() => {
			setCountdown(prev => prev === 0 ? 0 : prev - 1);
		}, 1000);
		return () => {
			clearInterval(dataInterval);
			clearInterval(countdownInterval);
		};
	}, []);

	return (
		<Layout>
			<Meta title="Din klasse" description="lol" />
			<Header color="white" hasBack={false} />
			<div className="min-h-screen bg-primary">
				<div className="flex flex-col justify-center h-screen mx-auto max-w-grid">
					<div className="flex flex-row items-start w-full min-h-1/2">
						<div className="flex flex-col w-25p">
							<Text type="body2" className="mb-16 italic text-white-100">Din klasse kode er</Text>
							<Text type="noveXLarge">{sktCode}</Text>
							<Text><a className="text-white-100" href={`mailto:?subject=${subject}&body=${body}`}>Send kode</a></Text>
							<Text type="body2" className="mt-64 italic text-white-100">Dit valgte tema er</Text>
							<Text type="pfLarge">{context.getTheme(theme).value}</Text>
						</div>
						<div className="flex flex-col w-59p">
							<Text type="body2" className="mb-16 italic text-white-100">Din klasse</Text>
							{!!(themeQuestions.length) && themeQuestions.map(themeQuestion => {
								const stat = stats.find(stat => stat.question_id === themeQuestion.id);
								let type = undefined;
								if (stat !== undefined) {
									type = Object.keys(stat).includes('choice_answers') ? 'choice' : 'slider';
								}
								return (
									<div key={themeQuestion.uuid} className="mb-36 h-100">
										<Text type="pfLarge" className="mb-8">{themeQuestion.content.body[0].title}</Text>
										<Stat value={stat?.count || 0}></Stat>
										<Text type="statText">{'\u00A0'}har svaret.</Text>
										{type !== undefined
											? type === 'choice'
												? <ChoiceAnswer data={stat} />
												: <SliderAnswer data={stat} />
											: null
										}
										<br />
										<Text type="statText">Det rigtige svar er{'\u00A0'}</Text>
										<Stat value={themeQuestion.answers.denmark}></Stat>
										<Text type="statText">{'\u00A0'}for Danmark og{'\u00A0'}</Text>
										<Stat value={themeQuestion.answers.kenya}></Stat>
										<Text type="statText">{'\u00A0'}for Kenya.</Text>
									</div>
								);
							})}
						</div>
						<div className="text-right w-16p">
							<Text type="body2" className="inline-block mb-8 text-white-100">Opdaterer om:{'\u00A0'}</Text>
							<Text className="inline-block ml-auto text-right text-white-100 w-34 text-22 font-playFair">{countdown}</Text>
						</div>
					</div>
					<div className="ml-auto">
						<Link to="/laerer" className="mr-16 font-playfair text-15">Tilbage</Link>
						<Button variant="externalLinkButton" href="/explore" target="_blank" className="ml-16">Videre til kortet</Button>
					</div>
				</div>
			</div>
			{/* <NewsTicker /> */}
		</Layout>
	);
};

export default Page;

export const query = graphql`
	query klasseQuery {
		allStoryblokEntry(
			filter: {field_component: {in: ["question-page", "answer-page"]}}
		) {
			nodes {
				slug
				content
				name
				tag_list
				full_slug
				uuid
				id
			}
		}
	}
`;

import gsap from 'gsap'; 

const interpolateValues = (setter, from, to, hasDecimals) => {
	let countObject = { count: from };
	gsap.to(countObject, {
		count: to,
		roundProps: hasDecimals ? '' : 'count',
		duration: 2,
		ease: 'circ.out',
		onUpdate: () => setter(hasDecimals ? Math.round(countObject.count * 10) / 10 : countObject.count),
	});
};

export default interpolateValues;